import { createRouter, createWebHashHistory } from 'vue-router';

/**
 * 路由
 * */
const routes = [
    {
        path:'/',
        name:'首页',
        component:()=>import("@/pages/page_index")
    },
    {
        path:'/book_info_v2',
        name:'详情',
        component:()=>import("@/pages/book_info_v2")
    },

]
const router = createRouter({
    history:createWebHashHistory(),
    routes
})

export default router
