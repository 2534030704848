<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  html, body {
    /*overflow-y: hidden;*/
    /*overflow-x: hidden;*/
  }

</style>
